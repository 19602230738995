import { trackEvent } from '../../packs/src/services/event-tracker'
import Quill from 'quill'

document.addEventListener('turbo:load', function () {
  addEditors()
})

document.addEventListener('modal:loaded', function () {
  addEditors()
})

var Link = Quill.import('formats/link')
class MyLink extends Link {
  static create(value) {
    let node = super.create(value)
    value = this.sanitize(value)
    if (
      !(
        value.startsWith('https') ||
        value.startsWith('http') ||
        value.startsWith('mailto')
      )
    ) {
      value = 'https://' + value
    }

    node.setAttribute('href', value)
    return node
  }
}

function trackAddLinkClicked(source) {
  trackEvent('$track_add_link_from_quill', {
    source,
  })
}

function addEditors() {
  let actsAsQuillAreas = document.querySelectorAll('.acts-as-quill-area')

  actsAsQuillAreas.forEach(function (actsAsQuillArea) {
    let outcomeId = actsAsQuillArea.dataset.id
    if (!document.getElementById('quill-editor-' + outcomeId)) {
      let content = actsAsQuillArea.value
      let editor = editorDom(outcomeId, content)
      actsAsQuillArea.parentElement.appendChild(editor)
      actsAsQuillArea.classList.add('hidden')
      const placeholder = actsAsQuillArea.getAttribute('placeholder')
      let bounds = actsAsQuillArea.dataset.bounds
      let toolbarType = actsAsQuillArea.dataset.quillToolbarType
      let theme = actsAsQuillArea.dataset.quillTheme
      let quillEditor = initQuill(
        editor,
        placeholder,
        bounds,
        toolbarType,
        theme
      )
      syncEditor(quillEditor, actsAsQuillArea)

      const quillSource = actsAsQuillArea.dataset.id
      const linkButton =
        quillEditor.container.parentElement.querySelector('.ql-link')
      linkButton.addEventListener('click', function () {
        trackAddLinkClicked(quillSource)
      })
    }
  })
}

function toolBarOptions(toolbarType) {
  if (toolbarType == 'simple') {
    return [['bold', 'italic', 'underline', 'link']]
  } else if (toolbarType == 'readme') {
    return [
      ['bold', 'italic', 'underline', { header: [2, 3] }, 'link'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ]
  } else {
    return [
      ['bold', 'italic', 'underline', { header: 3 }, 'link'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ]
  }
}

function formats(toolbarType) {
  if (toolbarType == 'simple') {
    return ['bold', 'italic', 'link', 'underline']
  }
}

function initQuill(
  editor,
  placeholder = 'Add here...',
  bounds,
  toolbarType,
  theme = 'bubble'
) {
  Quill.register(MyLink)

  const quill = new Quill(editor, {
    theme,
    modules: {
      toolbar: toolBarOptions(toolbarType),
    },
    formats: formats(toolbarType),
    placeholder: placeholder,
    bounds: bounds,
  })

  return quill
}

function editorDom(outcomeId, content) {
  let editor = document.createElement('div')
  editor.innerHTML = content
  editor.setAttribute('id', 'quill-editor-' + outcomeId)
  editor.dataset.id = outcomeId

  return editor
}

function syncEditor(quillEditor, actsAsQuillArea) {
  let observer = new MutationObserver(function () {
    actsAsQuillArea.value = quillEditor.root.innerHTML
    const event = new Event('change', { bubbles: true })
    actsAsQuillArea.dispatchEvent(event)
  })

  const observerOptions = {
    characterData: true,
    childList: true,
    attributes: true,
    subtree: true,
  }

  observer.observe(quillEditor.root, observerOptions)
}

window.quilify = function () {
  addEditors()
}
